// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("jquery-ui");
// require('timeago');

import Rails from '@rails/ujs';
import "@hotwired/turbo-rails";
window.Rails = Rails;

import { 
    registerComponent, // import the registerComponent function used to register the components
    Eventcalendar, // import component classes that needed to be regitered
    Datepicker,
    Button, 
    Checkbox,
} from '@mobiscroll/jquery';

registerComponent(Eventcalendar);
registerComponent(Datepicker);
registerComponent(Button);
registerComponent(Checkbox);

// var mobiscroll = require('@mobiscroll/jquery/dist/js/mobiscroll.jquery.min');

// mobiscroll.setOptions({
//     theme: 'ios',
//     themeVariant: 'light',
//     clickToCreate: false,
//     dragToCreate: false,
//     dragToMove: false,
//     dragToResize: false,
//     eventDelete: false
// });

// import { eventcalendar } from '.\esm5\mobiscroll.javascript.min.js'; // import the eventcalendar component function
// token input
// removed the nodemodules version as it seems to be out of date and doesn't have the allowFreeTagging parameter
// https://github.com/loopj/jquery-tokeninput
// import 'jquery-tokeninput';
import 'packs/mobiscroll.js';
import 'packs/every_page.js';
import 'packs/jquery-tokeninput-updated.js';
import 'packs/users.js';
import 'packs/user_image_upload.js';
import 'packs/messages.js';
import 'packs/payment_processing.js';
import 'packs/reservations.js';
// import '~timeago.js';
// import 'packs/time_ago_vendor.js'
// import 'packs/time_ago.js';

import 'packs/trix-editor-overrides.js';
// import "bootstrap";
import "popper.js";

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');


//robust
import 'packs/robust-bundle.js';

import 'packs/event_carousel.js';
import 'packs/events.js';

//Time Zone
import 'packs/time_zone.js';
import LocalTime from "local-time";
LocalTime.start();

// import 'packs/flipdown.min.js';
import 'packs/countdown.js';
// import "packs/hello.js.erb";

//event guests
// import 'packs/guest_lists.js';

//calendar
// import 'packs/fullcalendar.js';
import 'packs/notifications.js';

// cookie consent

import "cookieconsent";

import "packs/cookieconsent.js";

import { Notifier } from '@airbrake/browser';

if (NODE_ENV==="production") {
  const airbrake = new Notifier({
    projectId: 507022,
    projectKey: '81e353fe0318c5df2cf83581cd7b511a',
    environment: 'production'
  });
} else {
  const airbrake = new Notifier({
    projectId: 507022,
    projectKey: '81e353fe0318c5df2cf83581cd7b511a',
    environment: 'development'
  });
}

// jsCrop
// import 'packs/jscrop.js';
// import 'packs/crop_image.js';


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import "controllers"

// https://github.com/basecamp/trix/issues/247 if getting more trix errors
try {
  require("trix");
}
catch(err) {
  console.log("error requiring trix");
}

require("@rails/actiontext")

//maps search
window.initMap = function(...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

