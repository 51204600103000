// app/javascript/channels/notification_channel.js

import consumer from "./consumer"

// $(document).on('turbo:load', function () {
  
  consumer.subscriptions.create("Noticed::NotificationChannel", {
    connected() {
      // console.log("on the notification channel");
      // Called when the subscription is ready for use on the server
      
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      // Called when there's incoming data on the websocket for this channel
      // console.log(!data.hostel_property_id)
      // console.log(data.hostel_property_id==$("#page_hostel_property").data('hostel-property-id'))
      var orig_count=$(".navigation-bell").prop('data-count')
      orig_count = orig_count === undefined ? 0 : orig_count;
      var count = !!data.notification_count ? data.notification_count : 0 ;
      // console.log(!data.hostel_property_id);
      if (!data.hostel_property_id||data.hostel_property_id==$("#page_hostel_property").data('hostel-property-id')) {
        if (($("#notification_list")[0])&&(data.notification_list!=false)&&(!!data.notification_message)) {
          // $("#notification_list").load(location.href+" #notification_list>*","");
          
         $("#notification_list_group")[0].insertAdjacentHTML('afterbegin',`<a href="/notifications" class="list-group-item unread-notification"><div class="media"><div class="align-items-center"><i class="fa-solid fa-2x fa-fw ${data.notification_icon} m-1 text-info"></i></div><div class="media-body ml-2"><div class="d-flex justify-content-between notifications-title-alignment"><div class"align-items-center"><p><span class="p-0">${data.notification_message}</span></p></div><div class="notifications-date-alignment"><small class="text-muted align-items-center text-nowrap pl-3">recent</small></div></div></div></div></a>`)

        }
        // console.log(data);
          
          
          // $("#notification_icon").load(location.href+" #notification_icon>*","");
          // $("#nav-bell-small-screen").load(location.href+" #nav-bell-small-screen>*","");
          
          
        
        // console.log(`Count: ${count}`);
        // console.log(orig_count);
        // console.log($(".navigation-bell").prop('data-count'));
        // console.log(count);
        if (!!count&&(count>orig_count)) {
          document.title=`(${count}) Neesh`;
          if (!!$(".navigation-bell")[0]) {
            $(".navigation-bell").attr('data-count',count)
            $(".navigation-bell")[0].innerHTML = '<i class="fa fa-bell fa-inverse notification_icon_toggled"></i>';
          }
          
          if (!!$(".notification-icon-wide")[0]) {
            $(".notification-icon-wide").attr('data-count',count)
            $(".notification-icon-wide")[0].innerHTML = '<i class="fa fa-bell fa-inverse"></i>'
          }
        } else if (!count) {
          document.title=`Neesh`;
          if (!!$(".navigation-bell")[0]) {
            $(".navigation-bell").removeAttr("data-count");
            $(".navigation-bell")[0].innerHTML = '<i class="far fa-bell notification_icon_toggled"></i>';
          }
          
          if (!!$(".notification-icon-wide")[0]) {
            $(".notification-icon-wide").removeAttr("data-count");
            $(".notification-icon-wide")[0].innerHTML = '<i class="far fa-bell"></i>'
          }
        }
  
          
        
        }
      }
  });
// });