$(document).on('turbo:load', function() {


  if (!!$("#calendar-month-view-links")[0]) {
  
  
  require(['jquery', '@mobiscroll/jquery/dist/js/mobiscroll.jquery.min'], function ($) {
    var mobiscroll = require('@mobiscroll/jquery/dist/js/mobiscroll.jquery.min');

    mobiscroll.setOptions({
        theme: 'ios',
        themeVariant: 'light',
        clickToCreate: false,
        dragToCreate: false,
        dragToMove: false,
        dragToResize: false,
        eventDelete: false
    });
 
  
  
  $(function () {

    $('#calendar-month-view-links').mobiscroll().eventcalendar({
        responsive: {
              xsmall: {
                  view: {
                      calendar: {
                          type: 'month',
                      },
                      agenda: {
                          type: 'day'
                      }
                  }
              },
              custom: { // Custom breakpoint
                  breakpoint: 600,
                  view: {
                      calendar: {
                          labels: true
                      }
                  }
              }
          },
        // renderEvent: function (data) {
        //     return '<div class="md-full-event"><img class="md-full-event-img" src="https://img.mobiscroll.com/demos/' + data.original.img + '" />' +
        //         '<div class="md-full-event-details">' +
        //         '<div class="md-full-event-title">' + data.title + '</div>' +
        //         '<div class="md-full-event-location">' +
        //         '<div class="md-full-event-label">Location</div><div>' + data.original.location + '</div>' +
        //         '</div><div class="md-full-event-time">' +
        //         '<div class="md-full-event-label">Time</div><div>' + data.start + '</div>' +
        //         '</div></div></div>';
        // },
        onEventClick: function (event, inst) {
            const ev = event.event.original || event.event;
            Turbo.visit(ev.url);
            // mobiscroll.toast({
            //         message: ev.title + ' clicked' + ev.url
            //     });
        },
        onPageLoading: function (event, inst) {
          
          $.ajax({
            type: "get",
            url: '/eventscalendar/hosteljson?startdate=' + event.firstDay + '&enddate=' + event.lastDay,
            data: {
              format: 'json'
            },
            success: function(data){
              var events = [];
              for (var i = 0; i < data.length; i++) {
                  // console.log(data[i].url);
                  events.push({
                      start: new Date(data[i].start_time),
                      // end: data[i].end_time || '',
                      // allDay: data[i].allDay,
                      url: data[i].url,
                      title: data[i].event_name
                      // color: data[i].color
                  });
              }
              
              inst.setEvents(events);

              // mobiscroll.toast({
              //     message: 'New events loaded'
              // });
              
            }
          });
        }
    });

});

  
  
  });
  
  
  }
  
  
  
  
  if (!!$("#calendar-month-view-no-links")[0]) {
  
  
  require(['jquery', '@mobiscroll/jquery/dist/js/mobiscroll.jquery.min'], function ($) {
    var mobiscroll = require('@mobiscroll/jquery/dist/js/mobiscroll.jquery.min');

    mobiscroll.setOptions({
        theme: 'ios',
        themeVariant: 'light',
        clickToCreate: false,
        dragToCreate: false,
        dragToMove: false,
        dragToResize: false,
        eventDelete: false
    });
 
  
  
  $(function () {

    $('#calendar-month-view-no-links').mobiscroll().eventcalendar({
        responsive: {
              xsmall: {
                  view: {
                      calendar: {
                          type: 'month',
                      },
                      agenda: {
                          type: 'day'
                      }
                  }
              },
              custom: { // Custom breakpoint
                  breakpoint: 600,
                  view: {
                      calendar: {
                          labels: true
                      }
                  }
              }
          },
        // renderEvent: function (data) {
        //     return '<div class="md-full-event"><img class="md-full-event-img" src="https://img.mobiscroll.com/demos/' + data.original.img + '" />' +
        //         '<div class="md-full-event-details">' +
        //         '<div class="md-full-event-title">' + data.title + '</div>' +
        //         '<div class="md-full-event-location">' +
        //         '<div class="md-full-event-label">Location</div><div>' + data.original.location + '</div>' +
        //         '</div><div class="md-full-event-time">' +
        //         '<div class="md-full-event-label">Time</div><div>' + data.start + '</div>' +
        //         '</div></div></div>';
        // },
        onPageLoading: function (event, inst) {
          var hostel_property_id=$("#hostel_event_calendar").data('hostel-property-id');
          $.ajax({
            type: "get",
            url: '/eventscalendar/hosteljson?startdate=' + event.firstDay + '&enddate=' + event.lastDay + '&hp_id=' + hostel_property_id,
            data: {
              format: 'json'
            },
            success: function(data){
              var events = [];
              for (var i = 0; i < data.length; i++) {
                  events.push({
                      start: new Date(data[i].start_time),
                      // end: data[i].end_time || '',
                      // allDay: data[i].allDay,
                      url: data[i].url,
                      title: data[i].event_name
                      // color: data[i].color
                  });
              }
              
              inst.setEvents(events);

              // mobiscroll.toast({
              //     message: 'New events loaded'
              // });
              
            }
          });
        }
    });

});

  
  
  });
  
  
  }
  
  
  
});