$(document).on('turbo:load', function() {
  
  if (!!$("#messages")[0]) {
    
    $(document).on("click",".reply_to_message", function(event) {
      if (!!$(".new_message_form")[0]) { 
        var message_div=event.currentTarget.closest(".message_div")
        var message_id=message_div.getAttribute('data-message-id')
        var message_body=message_div.querySelector(".message_body")
        if (!!message_id) {
          $(".demo-reply").remove()
          $("#message_reply_to_message").val(message_id)
          $(".new_message_form")[0].insertAdjacentHTML('afterbegin',`<div class="card demo-reply bg-light text-muted"><div class="card-body container p-0"><div class="d-flex flex-row justify-content-between"><small>${message_body.outerHTML}</small><button class="btn-secondary btn-sm remove_demo_reply" type="button">X</button></div></div></div>`)
        }
        $("#message_body").focus()
      }
    })
    
    $(document).on("click",".remove_demo_reply", function() {
      removeReplyTo()
    })
    
    $("#new_message").submit(function() {
      setTimeout(function(){ //delay until after form is submitted
        removeReplyTo();
      }, 200);
    });
  
    
  }
  
  
  
  if (!!$("#mute_form")[0]) {
    $("input[name='mute']").change(function() {
      Rails.fire(document.getElementById('mute_form'),'submit');
    });
  }
  
});

function removeReplyTo() {
    $("#message_reply_to_message").val(''); // blank the input
    $(".demo-reply").remove()
      
}