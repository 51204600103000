$(document).on('turbo:load', function() {
  
  
  if (!!$("#cookieconsenttoc")[0])  {
    window.cookieconsent.initialise({
    container: document.getElementById("cookieconsenttoc"),
    palette:{
      popup: { background: "#F6EDC3", opacity: 0.5 },
      // button: { background: "#e0e0e0" },
    },
    hasTransition: true,
    revokable: true,
    onStatusChange: function(status) {
      console.log(this.hasConsented() ?
      'enable cookies' : 'disable cookies');
    },
    "theme": "edgeless",
    "position": "bottom",
    cookie: {
      domain: "neesh.co",
      secure: true
    },
    "content": {
      "header": 'Cookies used on the website!',
      // "message": 'This website uses cookies to improve your experience.',
      // "dismiss": 'Got it!',
      "allow": 'Allow cookies',
      "deny": 'Decline',
      // "link": 'Learn more',
      // "href": 'https://www.cookiesandyou.com',
      // "close": '&#x274c;',
      "policy": 'Revoke Cookie Consent',
      // "target": '_blank',
    },
    elements: {
      // header: '<span class="cc-header"></span>',
      // message: '<span id="cookieconsent:desc" class="cc-message">asdasdasd</span>',
      messagelink: '<span id="cookieconsent:desc" class="cc-message">This website uses cookies to improve your experience. <br> <div class="btn-group"><a aria-label="learn more about cookies" tabindex="0" class="cc-link btn btn-link" href="https://www.cookiesandyou.com" target="_blank">Learn more</a></span>',
      dismiss: '<a aria-label="dismiss cookie message" tabindex="0" class="cc-btn btn btn-sm btn-dark my-1 cc-dismiss">Got it!</a></div>',
      // allow: '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow"></a>',
      // deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny"></a>',
      // link: '<a aria-label="learn more about cookies" tabindex="0" class="cc-link text-white" href="https://www.cookiesandyou.com" target="_blank">Learn moooore</a>',
      // close: '<span aria-label="dismiss cookie message" tabindex="0" class="cc-close"></span>',
    }
    });
  } else {
  
    window.cookieconsent.initialise({
    container: document.getElementById("cookieconsent"),
    palette:{
      popup: { background: "#F6EDC3" },
      // button: { background: "#e0e0e0" },
    },
    hasTransition: true,
    revokable: false,
    onStatusChange: function(status) {
      console.log(this.hasConsented() ?
      'enable cookies' : 'disable cookies');
    },
    "theme": "edgeless",
    "position": "bottom",
    cookie: {
      domain: "neesh.co",
      secure: true
    },
    elements: {
      // header: '<span class="cc-header"></span>',
      // message: '<span id="cookieconsent:desc" class="cc-message">asdasdasd</span>',
      messagelink: '<span id="cookieconsent:desc" class="cc-message">This website uses cookies to improve your experience. <br> <div class="btn-group"><a aria-label="learn more about cookies" tabindex="0" class="cc-link btn btn-link" href="https://www.cookiesandyou.com" target="_blank">Learn more</a></span>',
      dismiss: '<a aria-label="dismiss cookie message" tabindex="0" class="cc-btn btn btn-sm btn-dark my-1 cc-dismiss">Got it!</a></div>',
      // allow: '<a aria-label="allow cookies" tabindex="0" class="cc-btn cc-allow"></a>',
      // deny: '<a aria-label="deny cookies" tabindex="0" class="cc-btn cc-deny"></a>',
      // link: '<a aria-label="learn more about cookies" tabindex="0" class="cc-link text-white" href="https://www.cookiesandyou.com" target="_blank">Learn moooore</a>',
      // close: '<span aria-label="dismiss cookie message" tabindex="0" class="cc-close"></span>',
    }
    });
    
  }
});