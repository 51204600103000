$(document).on('turbo:load', function () {
  // $('.modal').modal('hide');
  // $(".modal input[type=submit]").click(function() {
  //   setTimeout(function() {
  //     $(".modal").hide()
  //   }, 500);
  // });
  if (!!$("#navbar_arrow_toast")[0]) {
    var toastElList = [].slice.call(document.querySelectorAll('.toast'));
    var toastList = toastElList.map(function (toastEl) {
      return new bootstrap.Toast(toastEl, {animation: true, autohide: true, delay: 3000})
    });
    
    setTimeout(function(){ //delay until after form is submitted
        toastList[0].show();
      }, 2000);
    
  }
  
  // ###MEESH hacky but not sure what's causing the scroll issue
  if (!!$(".flash_alert_message")[0]) {
    window.scrollTo(0, 0);
  }
  
  
  if (!!$("#refresh_page_div")[0]) {
    refreshPage();
    // setTimeout(function() {
    //   if (!!$("#welcome_to_hostel")[0]&&window.pageYOffset==0) {
    //     $("#welcome_to_hostel")[0].scrollIntoView();
    //   }
    // }, 5000);
  }
  
  if (!!$("#cover_background_image")[0]) {
    refreshBackgroundImage();
  }
  
  //   if (!!$("#request_fullscreen")[0]) {
  // /* Get the documentElement (<html>) to display the page in fullscreen */
  //   var elem = document.documentElement;
    
  //   /* View in fullscreen */
  //   // function openFullscreen() {
  //     if (elem.requestFullscreen) {
  //       elem.requestFullscreen();
  //     } else if (elem.webkitRequestFullscreen) { /* Safari */
  //       elem.webkitRequestFullscreen();
  //     } else if (elem.msRequestFullscreen) { /* IE11 */
  //       elem.msRequestFullscreen();
  //     }
  //   // }
  // }
  
  $("#toggle_navbar").click(function() {
    var _mobileMenuOpen = $(".navbar-collapse").hasClass("show");
    // var _margin_added = $(".navbar-collapse").hasClass("mt-3");
    // if (_mobileMenuOpen === false && _margin_added===false) {
    //   $(".navbar-collapse").addClass("mt-3")
    // } else {
    //   $(".navbar-collapse").removeClass("mt-3")
    // }
  });
  $(document).click(
      function (event) {
      // debugger;
      var target = $(event.target);
      var _mobileMenuOpen = $(".navbar-collapse").hasClass("show");
      if (_mobileMenuOpen === true && !target.hasClass("toggle_navbar") && !target.hasClass("dropdown-toggle")) {
        $("#toggle_navbar").click();
      }
      
    }
  );
  
  // This is dealing with turbo bs where a failed form submission leads to javascript issues
  document.addEventListener("turbo:submit-end", (event) => {
      window.turboFormSubmissionFailed = !event.detail.success;
  
      if(window.turboFormSubmissionFailed) {
        document.dispatchEvent(new Event('turbo:visit'))
        document.dispatchEvent(new Event('turbo:before-cache'))
      }
    });
    document.addEventListener("turbo:render", () => {
      if(window.turboFormSubmissionFailed) {
        document.dispatchEvent(new Event('turbo:load'))
        window.turboFormSubmissionFailed = false;
      }
    });
  
  // $(".make-invisible").css('opacity','0');
  
  // $("input").click(
  //   function (event){
  //     var target = $(event.target);
  //     console.log(target);
  //   })

});

let refreshPage = function() {
  setTimeout(function() {
    if (!!$("#refresh_page_div")[0]) {
      window.location.reload(true);
      refreshPage();
    }
  }, 3600000);
    
};


let refreshBackgroundImage = function() {
  image_timeout = 2000
  setTimeout(() => {
    $.ajax({
      url: location.href.split('?')[0] + '/event_images/random_image',
      type: 'GET',
      dataType: 'text',
      success: function(url) {
        $.ajax({
          url: url,
          type: 'GET',
          success: function(data) {
            $('#cover_background_image img').attr('src', url);
            setTimeout(refreshBackgroundImage, image_timeout)
          }
        });
      }
    });
  }, image_timeout);
};