// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "field", "map", "hostellatitude", "hostellongitude", "hostelplaceid", "placeid", "placename", "placeaddress"]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap();
    }
  }


  initMap() {
    
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(this.data.get("hostellatitude") || 1.29, this.data.get("hostellongitude") || 103.85),
      zoom: 13,
      streetViewControl: false,
      zoomControl: true,
      mapTypeControl: false,
      fullscreenControl: false,
      // disableDefaultUI: true
    });
    const origin = { lat: -33.871, lng: 151.197 };
    
    
    if (this.fieldTarget.type != "hidden") {
      new ClickEventHandler(this.map, origin);
    }
    
    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29)
    });
    
    //need to have both below because sometimes one exists and not the other (i.e. when a new place is selected) 

    let place_id = $("#event_google_maps_place_id").val() || this.data.get("placeid");

    if (!!place_id) {
      var request = {
          placeId: place_id,
          fields: ['address_components', 'formatted_address', 'geometry', 'icon', 'name', 'url']
      };

      let service = new google.maps.places.PlacesService(this.map);
      service.getDetails(request, callback.bind(this));
      
      
    } 
    
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.bindTo('bounds', this.map);
    this.autocomplete.setFields(['address_components', 'formatted_address', 'geometry', 'icon', 'name', "place_id", "url"]);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));

  }
  
  

  placeChanged() {
    let place = this.autocomplete.getPlace();
    if (!place.geometry) {
      // window.alert(`No details available for ${place.name}`);
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(17);
    }

    this.marker.setPosition(place.geometry.location);
    this.marker.setVisible(true);
    this.marker.setAnimation(google.maps.Animation.DROP);
    if (!!this.infowindow) {
      this.infowindow.close(this.map, this.marker);
      this.infowindow.setContent("");
    }
    this.infowindow = new google.maps.InfoWindow({});

    setContentForInfoWindow(this.infowindow, place);
    this.marker.addListener("click", () => {
    this.infowindow.open(this.map, this.marker);
    });
    // this.latitudeTarget.value = place.geometry.location.lat();
    // this.longitudeTarget.value = place.geometry.location.lng();
    this.placeidTarget.value = place.place_id;
    
    
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault();
    }
  }
  
  
  atTheHostel() {
    // let place_name = $('#add_location').attr('data-place-name');
    let place_id = this.data.get("hostelplaceid");
    // address_field.val(place_name);
    $("#event_google_maps_place_id").val(place_id);
    initMap();
  }
  
  checkIfDeleted() {
    if (this.fieldTarget.value == "") {
      this.placeidTarget.value = "";
      $("#event_google_maps_place_id").val('')
      this.data.set("placeid","");
      this.initMap();
    }
    }

// The stimulus controller ends here -- important for fieldTarget, etc maybe?
}

function callback(place, status) {
  if (status == google.maps.places.PlacesServiceStatus.OK) {
    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(17);
    }

    this.marker.setPosition(place.geometry.location);
    this.marker.setVisible(true);
    this.marker.setAnimation(google.maps.Animation.DROP);
    if (!!this.infowindow) {
      this.infowindow.close(this.map, this.marker);
      this.infowindow.setContent("");
    }
    this.infowindow = new google.maps.InfoWindow({});
    setContentForInfoWindow(this.infowindow, place);
    this.marker.addListener("click", () => {
    this.infowindow.open(this.map, this.marker);
    });
    
    if ((!!place.formatted_address)&&
    ($("#google_calendar_link").attr('href') 
    && !$("#google_calendar_link").attr('href').includes("location"))) {
      // let event_id = $('#event_messages').attr('data-event-chat-id');
      //check if the location parameter doesn't exist
      $("#calendar_links").load(location.href+" #calendar_links>*",{address: place.formatted_address});
      // $.ajax({data: {address: encodeURIComponent(place.formatted_address)}, dataType: "script"});
    }
    
    
    // update DOM
    if (this.fieldTarget.type != "hidden") {
      this.fieldTarget.value = place.name;
    }
    if (this.hasPlacenameTarget) {
      this.placenameTarget.textContent = place.name;
    }
  } else {
    //# maybe want to add a flag or something to know that the place_id is no longer functional
    $("#show_map_info").hide();
  }
}


function isIconMouseEvent(e) {
  return "placeId" in e;
}

class ClickEventHandler {
  constructor(map, origin) {
    this.origin = origin;
    this.map = map;
    this.placesService = new google.maps.places.PlacesService(map);
    this.infowindow = new google.maps.InfoWindow();
    this.infowindowContent = document.getElementById("infowindow-content");
    this.infowindow.setContent(this.infowindowContent);
    // Listen for clicks on the map.
    this.map.addListener("click", this.handleClick.bind(this));
  }
  handleClick(event) {
    // console.log("You clicked on: " + event.latLng);

    // If the event has a placeId, use it.
    if (isIconMouseEvent(event)) {
      // console.log("You clicked on place:" + event.placeId);
      // Calling e.stop() on the event prevents the default info window from
      // showing.
      // If you call stop here when there is no placeId you will prevent some
      // other map click event handlers from receiving the event.
      event.stop();
      this.getPlaceInformation(event.placeId);
    }
  }
  getPlaceInformation(placeId) {
    const me = this;
    this.placesService.getDetails({ placeId: placeId }, (place, status) => {
      if (status === "OK") {
        me.infowindow.close();
        // FYI this does not close the marker for the original place in the event if one exists. not sure if we want to change that
        me.infowindow.setPosition(place.geometry.location);
        me.infowindow.setContent('<div><strong>' + place.name + '</strong><br>' + 
        place.formatted_address + '</div>'+
        // add "view on google maps
        '<div style="border-top: 1px solid rgb(204, 204, 204); margin-top: 9px; padding: 6px; font-size: 13px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-family: Roboto, Arial;">'+
        '<a href="'+place.url+'" target="_blank" rel="noopener" style="cursor: pointer; color: rgb(66, 127, 237); text-decoration: none;">View on Google Maps</a></div>'+
        '<div style="border-top: 1px solid rgb(204, 204, 204); margin-top: 9px; padding: 6px; font-size: 13px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-family: Roboto, Arial;">'+
        `<span data-place-id="${place.place_id}" data-place-name="${place.name}" rel="noopener" id="add_location" style="cursor: pointer; color: rgb(66, 127, 237); text-decoration: none;">Set as Event Location</span></div>`
        );
        me.infowindow.open(me.map);
      }
    });
  }
}

function setContentForInfoWindow(infowindow,place) {
    infowindow.setContent('<div><strong>' + place.name + '</strong><br>' + 
      place.formatted_address + '</div>'+
      // add "view on google maps
      '<div style="border-top: 1px solid rgb(204, 204, 204); margin-top: 9px; padding: 6px; font-size: 13px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-family: Roboto, Arial;">'+
      '<a href="'+place.url+'" target="_blank" rel="noopener" style="cursor: pointer; color: rgb(66, 127, 237); text-decoration: none;">View on Google Maps</a></div>'
  );
}

//not sure how to get this to use fieldTarget and stuff from the stimulus controller but seems to be working fine regardless
document.addEventListener('turbo:load', function() {
$("#event_form").on("click", "span#add_location", function() {
  let address_field=$("#event_location_address");
  if (address_field.attr('type') != "hidden") {
    let place_name = $('#add_location').attr('data-place-name');
    let place_id = $('#add_location').attr('data-place-id');
    address_field.val(place_name);
    $("#event_google_maps_place_id").val(place_id);
    initMap();
  }
  });
});