import { Controller } from "stimulus"
import Rails from "@rails/ujs";
export default class extends Controller {
  // static targets = ["entries", "pagination"]

  initialize() {
    // console.log("initialized");
  }

  connect() {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("inner-connected", true, true, null)
    this.element.dispatchEvent(event)
    // console.log("connected_inner");

  }

  disconnect() {
  }


}