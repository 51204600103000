// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'

// // you can also import a translation file
// import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static targets = ["utc","offset","type"]
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    // this.config = {
    //   enableTime: true,
    //   altInput: true,
    //   dateFormat: "Z"
    // };
  }
  
  connect() {
    
       //global options
    this.config = {
      enableTime: true,
      altInput: true,
      dateFormat: "Z",
      minDate: "today",
      minuteIncrement: 15
      // allowInput: true,
      // onReady: function(selectedDates, dateStr, instance) {
      //   let el = instance.altInput;
      //   function preventInput(event) {
      //       if ((event.keyCode!=8)&&(event.keyCode!=46)) {
      //         // event.preventDefault();
      //         return false;
      //       }
      //   }
        
      //   el.onkeypress = el.onkeydown = el.onkeyup = preventInput; // disable key events
      //   el.onpaste = preventInput; // disable pasting using mouse context menu

      //   el.style.caretColor = 'transparent'; // hide blinking cursor
      //   el.style.cursor = 'pointer'; // override cursor hover type text
      //   el.style.color = '#585858'; // prevent text color change on focus
      //   el.style.backgroundColor = '#f7f7f7'; // prevent bg color change on focus
      // },
    };

    //always call super.connect()
    super.connect();
    
    //part of the timezone craziness... seems to work
    var utc=this.data.get("utc");
    var stored_offset=this.data.get("offset");
    // !!stored offset is false if time zone isn't set but is still true if the offset is 0 because it's passed as a string 
    if ((!!utc)&&(!!stored_offset)) {
      var stored_offset_int=Number(stored_offset);
      var date= this.fp.parseDate(utc) || new Date(utc.replace(/-/g, "/"));
      // debugger;
      var client_offset = -date.getTimezoneOffset();
      var offset_diff = stored_offset_int - client_offset;
      if (!!offset_diff) {
        var adjusted_date=new Date(date.getTime()+offset_diff*60000);
        this.fp.setDate(adjusted_date);
      } else {
        this.fp.setDate(date);
      }
    }
    
    if (this.data.get("type")=="deadline_time") {
      var elem=this;
      $("#deadline_start_time").click(function() {
        // console.log($("#start_time"))
        // console.log($("#start_time").val());
        if ((!!$("#start_time").val())&&($(".min-guest-time-class").prop("disabled")==false)) {
          elem.fp.setDate(elem.fp.parseDate($("#start_time").val()))
        }
        // console.log(elem.fp.parseDate($("#start_time").val()))
        // console.log(this.fp.parseDate($("#start_time").val()))
        // console.log(this);
        // console.log(moots);
      });
    }
    if (this.data.get("type")=="cancellation_deadline_time") {
      var elem=this;
      $("#deadline_start_time").click(function() {
        // console.log($("#start_time"))
        // console.log($("#start_time").val());
        if ((!!$("#start_time").val())&&($(".min-guest-time-class").prop("disabled")==false)) {
          elem.fp.setDate(elem.fp.parseDate($("#start_time").val()))
        }
        // console.log(elem.fp.parseDate($("#start_time").val()))
        // console.log(this.fp.parseDate($("#start_time").val()))
        // console.log(this);
        // console.log(moots);
      });
    }
    if (this.data.get("type")=="cancellation_deadline_time") {
      var elem2=this;
      $("#cancellation_start_time").click(function() {
          elem2.fp.setDate(elem2.fp.parseDate($("#start_time").val()))
      });
    }
  }
  

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  // change(selectedDates, dateStr, instance) {
  //   console.log('the callback returns the selected dates', selectedDates)
  //   console.log('but returns it also as a string', dateStr)
  //   console.log('and the flatpickr instance', instance)
  // }
}

export function findTimeZone() {
      var jstz = require('jstimezonedetect');
      const oldIntl = window.Intl;
      try {
        window.Intl = undefined;
        const tz = jstz.determine().name();
        window.Intl = oldIntl;
        return tz;
      } catch (e) {
        // sometimes (on android) you can't override intl
        return jstz.determine().name();
      }
    }