import consumer from "./consumer";

// Prevent Duplicate Connections

let connections=[];
  
  let addConvoConnection = function(id) {
    connections.push(`conversation_${id}`);
  };
  let addHostelChatConnection = function(id) {
    connections.push(`hostelchat_${id}`);
  };
  let addEventConnection = function(id) {
    connections.push(`event_${id}`);
  };
  
  let removeConvoConnection = function(id) {
    let index = connections.indexOf(`conversation_${id}`);
    if (index > -1) {
      connections.splice(index, 1);
    }
  };
  let removeHostelChatConnection = function(id) {
    let index = connections.indexOf(`hostelchat_${id}`);
    if (index > -1) {
      connections.splice(index, 1);
    }
  };
  let removeEventConnection = function(id) {
    let index = connections.indexOf(`event_${id}`);
    if (index > -1) {
      connections.splice(index, 1);
    }
  };
    let connectedToConvo = function(id) {
    connections.indexOf(`conversation_${id}`) > -1;
  };
  let connectedToHostelChat = function(id) {
    connections.indexOf(`hostelchat_${id}`) > -1;
  };
  let connectedToEvent = function(id) {
    connections.indexOf(`event_${id}`) > -1;
  };



//create the connections

$(document).on('turbo:load', function () {
  let conversation_id = $('#conversation_messages').attr('data-conversation-id');
  let hostel_chat_id = $('#hostel_chat_messages').attr('data-hostel-chat-id');
  let event_id = $('#event_messages').attr('data-event-chat-id');
  if (!!conversation_id||!!event_id||!!hostel_chat_id) {
    submitMessageEnter();
    scrollBottom(true);
    submitMessageClick();
    markReadOnChange();
    checkOnlineStatusInterval();
    
    //if tab is hidden and then gets opened
    // not sure of the performance impact of this
    window.addEventListener("focus", markReadOnChange);
    
    $("#chat-tab").bind('click', () => {
        setTimeout(function() {
          scrollBottom(true);
          $("#chat-new-message").hide();
          markReadOnChange();
        }, 500);
        
        

      }
      );
  
    // possibly duplicate ways to prevent multiple subscriptions to the same channel which was causing 
    // issues including the same message appearing more than once. Some of this may be redundant and unnecessary
    // using the dynamic variable creation of window[] was the only thing that finally got it to stop
    if (!!conversation_id && !connectedToConvo(conversation_id) && (!window['convo_cable_'+conversation_id] || window['convo_cable_'+conversation_id].consumer.connection.disconnected)) {
      //Create ActionCable connection
      window['convo_cable_'+conversation_id]=consumer.subscriptions.create({
      channel: "RoomChannel",
      conversation_id: conversation_id,
    }, {
    connected() {
      // Called when the subscription is ready for use on the server
      addConvoConnection(conversation_id);
      // console.log(`Connected to Conversation: ${conversation_id}_${Math.random()}!`);
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
      removeConvoConnection(conversation_id);
      // console.log("disconnected from convo")
    },
  
    received(data) {
      // data.message = data.message.replace("example.org", location.host)
      // Called when there's incoming data on the websocket for this channel
      //if visible
      
   
      
      if (data.message && !!data.message && (data.message_visible === "Yes") && checkRightPage(data.conversation_id,data.hostel_chat_id,data.event_id)) {
        // console.log(data.bcasttoken);
        // console.log(connections);
        document.getElementById("message_list").insertAdjacentHTML('beforeend', data.message);
        $("#showNewMessages").removeClass("d-none").show();
        $("#showNewMessages").click(() => scrollBottom(true));
        $("#messages").scroll(() => {
          if($('#messages')[0].scrollHeight - $('#messages').scrollTop() < 600) {
            $("#showNewMessages").hide();
          } else {
            // console.log("value is",$('#messages')[0].scrollHeight - $('#messages').scrollTop())
          }
          });
        // $('#messages-table').append(data.message);
        scrollBottom();
        $("#send_message_button").prop("disabled",false)
        $("#send_message_button")[0].innerHTML = '<i class="fas fa-arrow-right"></i>'
        // mark message as read
        if (!document.hidden) {
          this.perform("mark_new_message_as_read", {message_id: data.message_id});
        }
      }
      
      //if unclear whether visible -- run extra visibility check for more security
      if (data.message && !!data.message && !data.message_visible) {
        this.perform("message_visible", {message_id: data.message_id, message: data.message});
      }
    }
  });
  }
  
  // Separated out the hostel rooms and the conversation rooms as identifying 
  // room by the combination of IDs was throwing the following error: 
  //Could not execute command from ({"command"=>"subscribe", "identifier"=>"{\"channel\":\"RoomChannel\",\"conversation_id\":\"1928\"}"}) 
  //[ActiveRecord::RecordNotFound - Couldn't find HostelProperty without an ID]
  
  //This is likely inefficient but wanted to avoid the error 
  if (!!hostel_chat_id && !connectedToHostelChat(hostel_chat_id) && (!window['hostel_cable_'+hostel_chat_id] || window['hostel_cable_'+hostel_chat_id].consumer.connection.disconnected)) {
    //Create ActionCable connection
    window['hostel_cable_'+hostel_chat_id]=consumer.subscriptions.create({
      channel: "RoomChannel",
      hostel_chat_id: hostel_chat_id,
    }, {
    connected() {
      // Called when the subscription is ready for use on the server
      addHostelChatConnection(hostel_chat_id);
      // console.log(`Connected to Hostel: ${hostel_chat_id}_${Math.random()}!`);
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
      removeHostelChatConnection(hostel_chat_id);
      // console.log("disconnected from hostel chat")
    },
  
    received(data) {
      // console.log(data.message);
      // data.message = data.message.replace("example.org", location.host)
  
      // Called when there's incoming data on the websocket for this channel
      //if visible
      if (data.message && !!data.message && (data.message_visible === "Yes") && checkRightPage(data.conversation_id,data.hostel_chat_id,data.event_id)) {
        // console.log(data.bcasttoken);
        // console.log(connections);
        document.getElementById("message_list").insertAdjacentHTML('beforeend', data.message);
        $("#showNewMessages").removeClass("d-none").show();
        $("#showNewMessages").click(() => scrollBottom(true));
        $("#messages").scroll(() => {
          if($('#messages')[0].scrollHeight - $('#messages').scrollTop() < 600) {
            $("#showNewMessages").hide();
          } else {
            // console.log("value is",$('#messages')[0].scrollHeight - $('#messages').scrollTop())
          }
          });
        // $('#messages-table').append(data.message);
        scrollBottom();
        $("#send_message_button").prop("disabled",false)
        $("#send_message_button")[0].innerHTML = '<i class="fas fa-arrow-right"></i>'
        
        // mark message as read
        if (!document.hidden) {
          this.perform("mark_new_message_as_read", {message_id: data.message_id});
        }
      }
      
      //if unclear whether visible -- run extra visibility check for more security
      if (data.message && !!data.message && !data.message_visible) {
        this.perform("message_visible", {message_id: data.message_id, message: data.message});
      }
    }
  });
  }
  
      //Did the same thing for events
  if (!!event_id && !connectedToEvent(event_id) && (!window['event_cable_'+event_id] || window['event_cable_'+event_id].consumer.connection.disconnected)) {
    //Create ActionCable connection
    window['event_cable_'+event_id]=consumer.subscriptions.create({
      channel: "RoomChannel",
      event_id: event_id,
    }, {
    connected() {
      // Called when the subscription is ready for use on the server
      addEventConnection(event_id);
      // console.log(`Connected to Event: ${event_id}_${Math.random()}!`);
      // document.getElementById("message_list").insertAdjacentHTML('beforeend', "asdasdasd");
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
      removeEventConnection(event_id);
      // console.log("disconnected from event")
    },
  
    received(data) {
      // data.message = data.message.replace("example.org", location.host)
  
      // Called when there's incoming data on the websocket for this channel
      //if visible
      if (data.message && !!data.message && (data.message_visible === "Yes") && checkRightPage(data.conversation_id,data.hostel_chat_id,data.event_id)) {
        // console.log(data.bcasttoken);
        // console.log(connections);
        $("#showNewMessages").removeClass("d-none").show();
        $("#showNewMessages").click(() => scrollBottom(true));
        $("#messages").scroll(() => {
          if($('#messages')[0].scrollHeight - $('#messages').scrollTop() < 600) {
            $("#showNewMessages").hide();
          } else {
            // console.log("value is",$('#messages')[0].scrollHeight - $('#messages').scrollTop())
          }
          });
        // $('#messages-table').append(data.message);
        // if(location.href.indexOf("view=chat") != -1) {
        document.getElementById("message_list").insertAdjacentHTML('beforeend', data.message);
        // }
        scrollBottom();
        if (!!$("#send_message_button")[0]) {
          $("#send_message_button").prop("disabled",false)
          $("#send_message_button")[0].innerHTML = '<i class="fas fa-arrow-right"></i>'
        }
        // mark message as read
        if (!document.hidden&&$("#event_chat_form:visible").length) {
          // console.log("new read message");
          this.perform("mark_new_message_as_read", {message_id: data.message_id});
        } else {
          // console.log("new unread message");
          $("#chat-new-message").removeClass("d-none").show();
        }
      }
      
      //if unclear whether visible -- run extra visibility check for more security
      if (data.message && !!data.message && !data.message_visible) {
        this.perform("message_visible", {message_id: data.message_id, message: data.message});
      }
    }
  });
  }
}
});


let markReadOnChange = function() {
    if ($("#chat-tab").length&&!$("#event_chat_form:visible").length) return;
    $("#chat-new-message").hide();
    let last_message_id=$('[data-message-id]').last().attr('data-message-id');
    if (!!last_message_id) {
      // console.log("change")
      $.ajax({
      type: "post",
      url: "/messages/markasread",
      data: {
      message_id: encodeURIComponent(last_message_id)
      },
      success: function() {
        // console.log("ajax");
      }
      });
    
}
};

   
function urlify(text) {
  // Doesn't work for just google.com
  var urlRegex = /((((ftp|https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g;
  // var urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  return text.replace(urlRegex, function(url) {
    
    if (!url.includes("http")) {
      var intro="//";
    } else {
      var intro="";
    }
    return '<a href="' + intro + url + '">' + url + '</a>';
  })
}

let submitMessageEnter = function() {
  $('#message_body').on('keydown', function(event) {
    if (event.keyCode===13 && !event.shiftKey) {
      let tribute_visible=$('.tribute-container').attr("style");
      if ((tribute_visible===undefined)||(tribute_visible==="display: none;")) {
        $('#message_body').val($('#message_body').val().replace('</div>','</div>'));
        $('#message_body').val(urlify($('#message_body').val()));
        $('#send_message_button').click();
        // event.target.value = ""; 
        event.preventDefault();
      }
    }
    // if (event.keyCode===83) {
    //   event.preventDefault();
    //   var e = new KeyboardEvent('keydown',{'keyCode':32,'which':32});
      
    // }
  });
};

let submitMessageClick = function() {
$("#new_message").bind("ajax:beforeSend", function(event,xhr,status){
   var val=$('#message_body').val()
   if (!!val) {
     
     var support = (function () {
      	if (!window.DOMParser) return false;
      	var parser = new DOMParser();
      	try {
      		parser.parseFromString('x', 'text/html');
      	} catch(err) {
      		return false;
      	}
      // 	alert("yo");
      	return true;
      })();
     
     if (support) {
        var parser = new DOMParser();
    		var doc = parser.parseFromString(val, 'text/html');
    	  
    	  var htmlValInit=doc.getElementsByTagName("div")[0];
    	  if (!!htmlValInit) {
          var htmlVal=doc.getElementsByTagName("div")[0].innerHTML;
          htmlVal = htmlVal.replace(/\s/g, '');
          htmlVal = htmlVal.replace(/\&nbsp;/g, '');
          htmlVal = htmlVal.replace(/(<br\ ?\/?>)+/, '');
    	  } else {
    	    var htmlVal=null;
    	  }
     }
     
    // if (!support||!!htmlVal) {
       $('#message_body').val('');
       $("#send_message_button").prop("disabled",true)
       $("#send_message_button")[0].innerHTML = '<div class="spinner_identifier text-center"><div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div></div>'
       scrollBottom(true);
    // }
   }
 });
};

var stringToHTML = function (str) {
      
    	// If DOMParser is supported, use it
    	if (support) {
    		var parser = new DOMParser();
    		var doc = parser.parseFromString(str, 'text/html');
    		return doc.body;
    	} else {
    
    	  // Otherwise, fallback to old-school method
      	var dom = document.createElement('div');
      	dom.innerHTML = str;
      	return dom;
    	}
     };

let scrollBottom = function(force) {
  if ($('#messages')[0]) {
    
    if(force || $('#messages')[0].scrollHeight - $('#messages').scrollTop() < 1000) {
      $('#messages').scrollTop($('#messages')[0].scrollHeight - $('#messages').height());
      $("#showNewMessages").hide();
    }
    // debugger;
  }
};

let checkRightPage = function(msg_conversation_id, msg_hostel_property_id, msg_event_id) {
  let conversation_id = $('#conversation_messages').attr('data-conversation-id');
  let hostel_chat_id = $('#hostel_chat_messages').attr('data-hostel-chat-id');
  let event_id = $('#event_messages').attr('data-event-chat-id');
  if ((msg_conversation_id === conversation_id)||(msg_hostel_property_id === hostel_chat_id)||(msg_event_id === event_id)) {
    return true;
    } else {
      return false;
    }
 };
 


let checkOnlineStatusInterval = function() {
  // https://www.freecodecamp.org/news/how-to-check-internet-connection-status-with-javascript/
  setTimeout(async () => {
      const result = await checkOnlineStatus();
      // const statusDisplay = document.getElementById("status");
      if (result) {
        if (!!!$(".spinner_identifier")[0]) {
          $("#send_message_button").prop("disabled",false)
        }
        $(".offline_message").remove()
      } else {
        $("#send_message_button").prop("disabled",true)
          if (!!!$(".offline_message")[0]&&!!$(".new_message_form")[0]) {
            $(".new_message_form")[0].insertAdjacentHTML('beforeend',`<div class="card offline_message text-center bg-light text-muted"><div class="card-body text-center container p-0"><small><i class="fa-solid fa-triangle-exclamation"></i>You are offline</small></div></div>`)
          }
        }
      checkOnlineStatusInterval();
    }, 10000); // probably too often, try 30000 for every 30 seconds
};



const checkOnlineStatus = async () => {
  try {
    const online = await fetch("/1pixel.png",{
          mode: 'no-cors',
          cache: 'no-cache'
      });
    // console.log(online.status);
    return online.status >= 200 && online.status < 300; // either true or false
  } catch (err) {
    // console.log(false);
    return false; // definitely offline
  }
};
