import { Controller } from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ["entries", "pagination", "up"]

  initialize() {
    let options = {
      rootMargin: '200px',
    };

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget);
  }

  disconnect() {
    if (this.hasPaginationTarget) {
      this.intersectionObserver.unobserve(this.paginationTarget);
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.loadMore();
      }
    });
  }

  loadMore() {
      var entries_type = this.entriesTarget.dataset.entriesType;
      if (!!entries_type) {
          var url_addition=`&type=${encodeURIComponent(entries_type)}`;
        } else {
          var url_addition="";
        }
      let next_page = this.paginationTarget.querySelector("a[rel='next']");
      if (next_page == null) { return }
      let url = next_page.href + url_addition;
      // console.log(url);
      if (this.loading) { return }
      this.loading = true;
      
    if (this.hasUpTarget) {
    
      this.entriesTarget.insertAdjacentHTML('afterbegin', '<div class="spinner_identifier" style="text-align:center;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>');
      
      Rails.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {

          this.entriesTarget.insertAdjacentHTML('afterbegin', data.entries);
          this.paginationTarget.innerHTML = data.pagination;
          
          
          // for some reason the below only works with the full elements and not jquery
          var elementTop = document.getElementsByClassName('current_top')[1].offsetTop;
          var divTop = document.getElementById('messages').offsetTop;
          var elementRelativeTop = elementTop - divTop;
          $('#messages').scrollTop(elementRelativeTop);
          
          $(".spinner_identifier").remove();
          this.loading = false;
          
        }
      });
    
      
    } else {
      
      

      this.entriesTarget.insertAdjacentHTML('beforeend', '<div class="spinner_identifier" style="text-align:center;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>');
    
      Rails.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        success: (data) => {
          this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
          this.paginationTarget.innerHTML = data.pagination;
          $(".spinner_identifier").remove();
          this.loading = false;
        }
      });
    
    }
    
  }
}