var paymentFailed = function() {
  Turbo.visit('.?payment=unsuccessful')
}

var paymentSuccessful = function() {
  Turbo.visit('.?payment=ok')
}

var checkPaymentStatus = function(times) {
  if(times < 0) {
    paymentFailed(); 
  } else {
    $.ajax({url: "payment_status",
          contentType: 'application/json',
          dataType: 'json',
           success: function(response) {
            console.log(response.status)
            switch (response.status) {
              case 'ok':
                paymentSuccessful();
                break;
                case 'wait':
                  setTimeout(() => checkPaymentStatus(times-1), 3000);
                  break;
              default:
              case 'not ok':
                paymentFailed();
                break;
              
            }
          },
          error: () => {}
        }
      );
  }
}

$(document).on('turbo:load', function() {
    if ($('#payment_processing_check')[0]) {
      checkPaymentStatus(10);
    
    }
});