$(document).on('turbo:load', function() {
  // debugger;
  if (!!$("#event_event_name")[0]) {
    
    
    
    // https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
    // disable mousewheel on a input number field when in focus
    // (to prevent Chromium browsers change the value when scrolling)
    $('form').on('focus', 'input[type=number]', function (e) {
      $(this).on('wheel.disableScroll', function (e) {
        e.preventDefault();
      });
    });
    $('form').on('blur', 'input[type=number]', function (e) {
      $(this).off('wheel.disableScroll');
    });
    
    
    
    
    
    updatePaidCheckboxUI(); 
  
  
    $(".payment_checkbox").bind('click', updatePaidCheckboxUI);
    
    setTimeout(() => {
    $(".start-time-class").prop("required",true);
    $(".start-time-class").addClass("is-invalid");
    $(".start-time-class").removeAttr('readonly');
    $(".start-time-class").attr("onkeydown", "return false;")
    $(".start-time-class").attr("onkeyup", "return false;")
    $(".start-time-class").attr("onkeypress", "return false;")
    $(".start-time-class").attr("onpaste", "return false;")
    }, 100);
    
    $(".start-time-class").change(function() {
        if (!!$("#start_time").val()) {
          $("#deadline_start_time").removeClass("d-none").show();
          $("#cancellation_start_time").removeClass("d-none").show();
        } else {
          $("#deadline_start_time").hide();
          $("#cancellation_start_time").hide();
        }
      }
    );
    
    $("#deadline_start_time").click(function() {
      $("#deadline_time").val($("#start_time").val());
    });
    $("#cancellation_start_time").click(function() {
      $("#cancellation_deadline_time").val($("#start_time").val());
    });
    
    checkEventPrice();
    checkEventMinGuests();
    
    if (!!$(".event_update")[0]) {
      $(".payment_checkbox.to_host").attr("disabled", true);
      $('[data-target=".collapseExample"]').removeAttr('data-toggle');
    }
    
    $("#event_price").on('input', function() {
      checkEventPrice();
      checkEventMinGuests();
    });
      
      
    $("#event_min_guests").on('input', function() {
        checkEventMinGuests();
    });
    
    $("#event_min_guests").on('blur', function() {
      var raw_min_guests=$("#event_min_guests").val()
      if (!!raw_min_guests&&!$(".min-guest-time-class")[0].value) {
        
        $("#price_group")[0].scrollIntoView();
      }
    
    });
    
  }
  // }
    
  if (!!$("[id*=rating-5]")[0]) {
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    if(!isChrome) {
      $(".star-cb-group > input + label").addClass('notChrome');
    }
    setTimeout(function() {
      $(".star-cb-group *").css("font-size", "0px");
    }, 100);
    
    setTimeout(function() {
      $(".star-cb-group *").css("font-size", "2rem");
    }, 500);
  }
  
  
  if (!!$("#payment_deadline")[0]) {
    var deadline=new Date($('#payment_deadline').attr('data-datetime')).getTime();
    var timenow=new Date().getTime();
    var distance = ((deadline-timenow)+2000);
    setTimeout(() => {
      if (!!$("#payment_deadline")[0]) {
        var new_deadline=new Date($('#payment_deadline').attr('data-datetime'));
        var new_timenow=new Date();
        if (new_deadline < new_timenow) {
          $("#event_responses").load(window.location.href + " #event_responses");
        }
      }
    }, distance);
  }

  // if (!!$("#guests-tab")[0]) {
    
    
  //   let observer = new MutationObserver(mutationRecords => {
  //     $("#guest_list").load(location.href+" #guest_list>*","");
  //     $("#event_actions_container").load(location.href+" #event_actions_container>*","");
      
  //     // console.log(mutationRecords); // console.log(the changes)
  //     // console.log("yo");
  //   });
    
  //   // observe everything except attributes
  //   observer.observe($("#guest_list_update_parent")[0], {
  //     childList: true, // observe direct children
  //     subtree: true, // and lower descendants too
  //     characterDataOldValue: true // pass old data to callback
  //   });

  
  // }
});

let updatePaidCheckboxUI = function() {
  var raw_price=$("#event_price").val()
  var price=Number(raw_price);
  if(!!raw_price) {
    $(".payment_checkbox").attr("disabled", true);
    $('[data-target=".collapseExample"]').removeAttr('data-toggle');
    
  } else {
    // $(".payment_checkbox.to_host").removeAttr("checked");
    if ((!$(".event_update")[0])||(!!$(".event_paid")[0])) {
      $(".payment_checkbox").removeAttr("disabled");
      $('[data-target=".collapseExample"]').attr('data-toggle', 'collapse');
    }
    
  } 
  
    $(".disable_on_load").prop( "disabled", true );
  setTimeout(function() {
  if($(".payment_checkbox").is('.to_host:checked')) {
    // alert('checked');
      $(".admin_controls").hide();
      $(".admin_controls input").val('');
      $(".user_controls").show();
      $(".collapseExample").addClass('show');
      
      
      $("#event_price").prop("required",true);
      
      if (!!$("#event_event_open_to_public")[0]) {
        $("#event_event_open_to_public").attr("disabled", false);
        $(".public-label").removeClass("text-muted")
      }

  } else {
      $(".user_controls").hide();
      $(".user_controls input").val('');
      $(".admin_controls").show();
      $(".collapseExample").removeClass('show');
      $("#event_price").prop("required",false);
      $("#event_price").val('');
      
      if (!!$("#event_event_open_to_public")[0]) {
        $(".public-label").addClass("text-muted");
        $("#event_event_open_to_public").attr("disabled", true);
        if ($("#event_event_open_to_public").is(':checked')) {
          $("#event_event_open_to_hostel_property").prop("checked",true)
        }
      }
  }
  }, 300);
  
};

let checkEventPrice = function() {
  var raw_price=$("#event_price").val()
  var price=Number(raw_price);
  
  if (!!raw_price) {
    // $("#charge_button").removeClass("btn-secondary").addClass("btn-primary");
    $("#event_min_guests").prop("disabled",false);
    $(".min-guest-time-class").prop("disabled",false);
    $(".min-guest-time-class").addClass("bg-white");
    $("#deadline_start_time").prop("disabled",false);
    
    
    checkEventMinGuests();
    updatePaidCheckboxUI();
  } else {
    // $("#charge_button").removeClass("btn-primary").addClass("btn-secondary");
    
    setTimeout(() => {
      var raw_price=$("#event_price").val()
      if (raw_price == '') {
          $("#event_min_guests").val('');
          $("#event_min_guests").prop("disabled",true);
          $(".min-guest-time-class").prop("disabled",true);
          $(".min-guest-time-class").val('');
          $(".min-guest-time-class").removeClass("bg-white");
          $("#deadline_start_time").prop("disabled",true);
          checkEventMinGuests();
          updatePaidCheckboxUI();
      }
    }, 2000);
    // $("#charge_button").click();
  }
};
    
let checkEventMinGuests = function() {
  var raw_min_guests=$("#event_min_guests").val()
  var event_min_guests=Number(raw_min_guests)
  if (!!raw_min_guests) {
    $(".min-guest-time-class").prop("required",true);
    $(".min-guest-time-class").addClass("is-invalid");
    $(".min-guest-time-class").removeAttr('readonly');
    $(".min-guest-time-class").attr("onkeydown", "return false;")
    $(".min-guest-time-class").attr("onkeyup", "return false;")
    $(".min-guest-time-class").attr("onkeypress", "return false;")
    $(".min-guest-time-class").attr("onpaste", "return false;")
  } else {
    $(".min-guest-time-class").prop("required",false);
    $(".min-guest-time-class").attr('readonly',true);
    // $(".min-guest-time-class").removeClass("bg-white").removeClass("is-invalid");
    $(".min-guest-time-class").removeClass("is-invalid");
  }
  
};