var jstz = require('jstimezonedetect');

$(document).on('turbo:load', function() {
    if (!!$('#event_client_time_zone')[0]) {
        var tza=Intl.DateTimeFormat().resolvedOptions().timeZone;
        var tzb=findTimeZone();
        $('#event_client_time_zone')[0].value = tza||tzb;
        if (!$("#event_time_zone").val()) {
            $.ajax({url: "/event_functions/update_time_zone",data: {time_zone_a: tza, time_zone_b: tzb}});
        }
    }
});


export function findTimeZone() {
      const oldIntl = window.Intl;
      try {
        window.Intl = undefined;
        const tzb = jstz.determine().name();
        window.Intl = oldIntl;
      return tzb;
      } catch (e) {
        // sometimes (on android) you can't override intl
        return jstz.determine().name();
      }
    }
