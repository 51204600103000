$(document).on('turbo:load', function() {
    
    if ($('#upload_user_image_form')[0]) {

    // upload image  
    document.getElementById("user_image_upload_file").onchange = function() {

    // $("#btnSubmit").click(function (event) {

        //stop submit the form, we will post it manually.
        // event.preventDefault();

        // Get form
        var form = $('#upload_user_image_form')[0];

        // Create an FormData object 
        var data = new FormData(form);
        
        $("#profile_image")[0].innerHTML = '<div class="spinner_identifier m-5" style="text-align:center;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>'
        // If you want to add an extra field for the FormData
        // data.append("CustomField", "This is some extra data, testing");

        // disabled the submit button
        // $("#btnSubmit").prop("disabled", true);

        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: "/users/upload_image",
            data: data,
            processData: false,
            contentType: false,
            dataType: "script",
            cache: false,
            timeout: 600000,
            success: function (data) {
                
                
                // $("#result").text(data);
                // console.log("SUCCESS : ", data);

            },
            error: function (e) {
                
                document.getElementById("user_image_upload_file").value=''
                $(".spinner_identifier").remove();
                // $("#result").text(e.responseText);
                console.log("ERROR : ", e);
                // $("#btnSubmit").prop("disabled", false);

            }
        });
    };


    //delete image
    $("#delete_image_submit").click(function (event) {
      
        //stop submit the form, we will post it manually.
        event.preventDefault();
        
        // Get form
        var form = $('#delete_image_form')[0];

        // Create an FormData object 
        var data = new FormData(form);
      
        // disabled the submit button
        $("#delete_image_submit").prop("disabled", true);
        $("#profile_image")[0].innerHTML = '<div class="spinner_identifier m-5" style="text-align:center;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>'
        
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: "/users/delete_image",
            data: data,
            processData: false,
            contentType: false,
            dataType: "script",
            cache: false,
            timeout: 600000,
            success: function (data) {
                
                
                // $("#result").text(data);
                // console.log("SUCCESS : ", data);

            },
            error: function (e) {

                // $("#result").text(e.responseText);
                document.getElementById("user_image_upload_file").value='';
                console.log("ERROR : ", e);
                $("#delete_image_submit").prop("disabled", false);

            }
        });
        
        
        
    });


    }
    
    
    
    if ($('#event_guest_upload_photos_form')[0]) {
        document.getElementById("event_guest_upload_photos").onchange = function() {
        if ($("#event_guest_upload_photos")[0].files.length > 15) {
            $("#image-limit-alert")[0].classList.toggle('d-none', false);
        } else {
        $("#image-limit-alert")[0].classList.toggle('d-none', true);
        // $("#btnSubmit").click(function (event) {

        //stop submit the form, we will post it manually.
        // event.preventDefault();

        // Get form
        var form = $('#event_guest_upload_photos_form')[0];

        // Create an FormData object 
        var data = new FormData(form);

        // If you want to add an extra field for the FormData
        // data.append("CustomField", "This is some extra data, testing");

        // disabled the submit button
        // $("#btnSubmit").prop("disabled", true);
        $('#event_guest_photos')[0].insertAdjacentHTML('beforebegin', '<div class="spinner_identifier m-5" style="text-align:center;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>')
        $("#event_guest_upload_photos_form_div").hide();
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: "/users/upload_photos",
            data: data,
            processData: false,
            contentType: false,
            dataType: "script",
            cache: false,
            timeout: 600000,
            success: function (data) {
                
                
                // $("#result").text(data);
                // console.log("SUCCESS : ", data);
                setTimeout(() => 
                {
                    $("#event_guest_upload_photos_form_div").removeClass("d-none").show();
                }, 30);
                
                
            },
            error: function (e) {
                $("#event_guest_upload_photos_form_div").removeClass("d-none").show();
                $(".spinner_identifier").remove();
                // $("#result").text(e.responseText);
                console.log("ERROR : ", e);
                // $("#btnSubmit").prop("disabled", false);

            }
        });
    }
    };
    
    }
    
    
    
    //     $(".event_delete_photo").click(function (event) {
      
    //     //stop submit the form, we will post it manually.
    //     event.preventDefault();
    //     // Get form
    //     var form = event.currentTarget.parentElement;
    //     var clickbox=event.currentTarget;
    //     clickbox.innerHTML = '<div class="spinner_identifier" style="text-align:center;"><div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div></div>';
    //     // clickbox.html('<div class="spinner_identifier m-5" style="text-align:center;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>');
    //     // console.log(clickbox)
    //     // Create an FormData object 
    //     var data = new FormData(form);
      
    //     // disabled the submit button
    //     $(".event_delete_photo").prop("disabled", true);
        
    //     $.ajax({
    //         type: "POST",
    //         enctype: 'multipart/form-data',
    //         url: "../users/delete_upload_photo",
    //         data: data,
    //         processData: false,
    //         contentType: false,
    //         dataType: "script",
    //         cache: false,
    //         timeout: 600000,
    //         success: function (data) {
                
    //             // $("#result").text(data);
    //             // console.log("SUCCESS : ", data);

    //         },
    //         error: function (e) {

    //             // $("#result").text(e.responseText);
    //             console.log("ERROR : ", e);
    //             document.getElementById("event_guest_upload_photos").value='';
    //             $(".event_delete_photo").prop("disabled", false);

    //         }
    //     });
        
        
        
    // });
    

    if (!!$("#signup_page_header")[0]) {
        var imgInp=document.getElementById("user_image");
        imgInp.onchange = evt => {
            const [file] = imgInp.files;
            if (file&&file.type.includes("image")) {
                document.getElementById("user_image_preview").src = URL.createObjectURL(file)
                $("#user_image_preview").removeClass('d-none').addClass('mb-2').show();
            } else {
                document.getElementById("user_image_preview").src = "#"
                $("#user_image_preview").hide()
            }
        } 
    }

    
  });