import { Controller } from "stimulus"
import Rails from "@rails/ujs";
export default class extends Controller {
  // static targets = ["entries", "pagination"]

  initialize() {
    // console.log("initialized")
  }

  connect() {
    // console.log("connected")
  }

  disconnect() {
  }

  connectedInner() {
    // location.reload();
    // console.log("innerprereload")
    window.location.reload(true);
    // console.log("innerpostreload")
  }
  
  connectedInnerEventGuests() {
    $("#guest_list").load(location.href+" #guest_list>*","");
    $("#event_actions_container").load(location.href+" #event_actions_container>*","");
    $("#description_display").load(location.href+" #description_display>*","");
    
    $('.modal-backdrop').remove() // removes the grey overlay.
  }
  
  // connectedInnerEventPhotos() {
  //   $("#event_guest_photos").load(location.href+" #event_guest_photos>*","");
  //   if (!!document.getElementById("event_guest_upload_photos")) {
  //     document.getElementById("event_guest_upload_photos").value='';
  //   }
  //   $(".event_delete_photo").prop("disabled", false);
  //   $(".spinner_identifier").remove();
  //   $('.modal').modal('hide')
  // }
  
  eventCarouselReload() {
    $("#event_carousel").load(location.href+" #event_carousel>*","");
  }
}