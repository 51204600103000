document.addEventListener('turbo:load', function() {
// Defining if statement because two token-input-lists were showing up when you used the back and forward buttons (likely turbolinks caused) -- commented out when turned off back caching
// if (!$(".token-input-list")[0]) {
    // user search by name
    $("#user_list").tokenInput("/users/search.json", {searchDelay: 150, hintText: "Search", searchingText: "Searching...", preventDuplicates: true, prePopulate: $('#user_list').data('users'),
        onAdd: function() {
            var list=$("#user_list").tokenInput( 'get' );
            $.ajax({
                  url: '/conversations/search',
                  data: { list },
              });
         },
        onDelete: function() {
            var list=$("#user_list").tokenInput( 'get' );
            $.ajax({
                url: '/conversations/search',
                data: { list },
            });
        },
    });
    // reservation search by name, email, id
    $("#user_list_with_extended_info").tokenInput("/users/search_with_email.json", {searchDelay: 150, hintText: "Search", searchingText: "", preventDuplicates: true, allowFreeTagging: true, tokenValue: "name", prePopulate: $('#user_list_with_extended_info').data('guests')} );
    
    //admin search
    $("#admin_user_search").tokenInput("/users/search_with_email.json", {searchDelay: 150, hintText: "Search", searchingText: "", preventDuplicates: true, tokenValue: "name"} );
// }
    
    $("#hostel_guest_list").tokenInput("/users/guest_search.json", {searchDelay: 150, hintText: "Search", searchingText: "Searching...", preventDuplicates: true, prePopulate: $('#hostel_guest_list').data('users'),
        onAdd: function() {
            var list=$("#hostel_guest_list").tokenInput( 'get' );
            $.ajax({
                  url: '/reservations/search',
                  data: { list },
              });
         },
        onDelete: function() {
            var list=$("#hostel_guest_list").tokenInput( 'get' );
            $.ajax({
                url: '/reservations/search',
                data: { list },
            });
        },
    });
    
// profile updates
 if (!!$("#update_bio")[0]) {
    $("#bio").blur(function() {
        $("#update_bio").click();
    })
    
     
    }
    
    if (!!$("#modal_block_button")[0]) {
        $("#modal_block_button").click(function() {
            $("#user_block_submit").click()
        //     var promptText="Are you sure you want to block this user? You won't be able to directly message each other and they won't be able to see your profile and created events (except events they are already attending). However, they will still see your messages in the hostel chat and group/event chats where you both are members. You will not be visible in event guestlists to them but they will still be able to attend events you are attending. Please add an optional reason below for blocking this user:"
        //     var reason=prompt(promptText)
        //     console.log(asd);
        });
    }
    
    if (!!$("#user_country")[0]) {
        
        $("#user_country").change(function() {
            // if (!!$("#update_country")) {
            $("#update_country").click();
            // } else {
                // $(".edit_user").submit()
            // }
                //for hostel captain requests
                
            // if ($("#stripe_country_message")[0]) {
                // setTimeout(function(){ //delay until after form is submitted
                //     $("#stripe_country_message").load(location.href+" #stripe_country_message>*","");
                // }, 1200);
            // }
         });
    }
    
    if($('#signup_page')[0]) {
        $(".form-control-no-validations").removeClass("form-control");
        
    }
    
    if (!!$("#user_notification_settings")[0]) {
        // $("input[name='delivery_method']").change(function() {
        //     Rails.fire(document.getElementById('user_notification_settings'),'submit');
        // });
        
        $(".form-control-no-validations").removeClass("form-control");
        $(".telephone-field").removeClass("form-control")
        // $(".telephone-field").removeClass("is-valid")
        // $(".telephone-field").removeClass("custom-select:valid")
        // $(".telephone-field").removeClass("custom-select:is-valid")
        $('.whatsapp_send_code').click(function() {
           $.ajax({
                type: 'POST',
                url: 'phone_verification_send?channel=whatsapp',
                success: (data) => {
                    $('#whatsapp_send_code').hide();
                    $('#verification_entry_whatsapp').removeClass('d-none').show();
                }
            });
        });
        
        
        $('#whatsapp_submit').click(function() {
            var otp=$('#whatsapp_code').val()
           $.ajax({
                type: 'POST',
                url: `phone_verification_check?channel=whatsapp&code=${otp}`,
                success: (data) => {
                    // Turbo.visit('edit?view=notifications')
                },
                error: () => {
                    $('#otp_whatsapp_error').removeClass('d-none').show();
                }
            });
        });
        
        
        $('.sms_send_code').click(function() {
           $.ajax({
                type: 'POST',
                url: 'phone_verification_send?channel=sms',
                success: (data) => {
                    $('#sms_send_code').hide();
                    $('#verification_entry_sms').removeClass('d-none').show();
                }
            });
        });
        
        
        $('#sms_submit').click(function() {
            var otp=$('#sms_code').val()
           $.ajax({
                type: 'POST',
                url: `phone_verification_check?channel=sms&code=${otp}`,
                success: (data) => {
                    // Turbo.visit('edit?view=notifications')
                },
                error: () => {
                    $('#otp_sms_error').removeClass('d-none').show();
                }
            });
        });
        
    }

});
