import { Controller } from "stimulus"
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = ["fullList","up","down","remove","listItem","container","url","itemText","savedListItem","removeSavedListItem","searchListOptions"]

  initialize() {
 }

  connect() {
  }

  disconnect() {
  }

  up(event) {
    const index = this.upTargets.findIndex(t => t === event.target);
    const listItemElement = this.listItemTargets[index];
    listItemElement.previousElementSibling.insertAdjacentElement('beforebegin', listItemElement);
  }

  down(event) {
    const index = this.downTargets.findIndex(t => t === event.target);
    const listItemElement = this.listItemTargets[index];
    const nextListItemElement = listItemElement.nextElementSibling;
    nextListItemElement.insertAdjacentElement('afterend', listItemElement);
 }
 
 
 remove(event) {
    var index = this.removeTargets.findIndex(t => t === event.target);
    if(index == -1) {//Sometimes selects the icon instead of the button element, select the parent
      index = this.removeTargets.findIndex(t => t === event.target.parentElement);
    }
    const listItemElement = this.listItemTargets[index];
    listItemElement.remove();
 }
 
 add(event) {
  if (!this.hasListItemTarget) {
      
      const templateId = this.data.get("listItemTemplateId");
      const template = document.getElementById(templateId);
      this.containerTarget.appendChild(template.content.cloneNode(true));
      this.itemTextTarget.focus();
  }
 }
 
 submit(event) {
    var url = this.urlTarget.dataset.addUrl;
    var value=this.itemTextTarget.value;
    var userId=this.urlTarget.dataset.userId;
    const listItemElement = this.listItemTarget;
    if (!value) {
        listItemElement.remove();
    } else {
        Rails.ajax({
          type: 'POST',
          url: url,
          data: `interest=${encodeURIComponent(value)}&user_id=${encodeURIComponent(userId)}`,
          dataType: 'json',
          success: (data) => {
            if (!!data.partial) {
              this.fullListTarget.insertAdjacentHTML('beforeend', data.partial);
              $("#search_list_options").load(location.href+" #search_list_options>*");
            }
            listItemElement.remove();
          },
          error: (data) => {
            listItemElement.remove();
          }
        });
        
    }
    // regardless, refresh the search suggestion div
    
    // ,"", () => {
    //   // $("#interest_search_input").focus();
    //   this.itemTextTarget.focus();
    // });
    // // setInterval(() =>    $("#interest_search_list").focus(), 1000);
    // // 
 }
 
 keydown(event) {
    if (event.key == "Enter") {
      this.submit(event);
    }
  }
  
  removeSaved(event) {
    var index = this.removeSavedListItemTargets.findIndex(t => t === event.target);
    
    if(index == -1) {//Sometimes selects the icon instead of the button element, select the parent
      index = this.removeSavedListItemTargets.findIndex(t => t === event.target.parentElement);
    }
    // if(index == -1) debugger;

    
    const savedListItemElement = this.savedListItemTargets[index];
    var userId=this.urlTarget.dataset.userId;
    var interest=savedListItemElement.textContent;
    var url=this.urlTarget.dataset.removeUrl;
    if (!!interest) {
        Rails.ajax({
          type: 'POST',
          url: url,
          data: `interest=${encodeURIComponent(interest)}&user_id=${encodeURIComponent(userId)}`,
          dataType: 'json',
          success: (data) => {
            if (!!data.partial) {
              savedListItemElement.parentElement.remove();
              $("#search_list_options").load(location.href+" #search_list_options>*");
            }
          }
        });
    }
  }
  
  search(event) {
    var url = this.urlTarget.dataset.searchUrl;
    var value=this.itemTextTarget.value;
    if (!!value) {
      Rails.ajax({
        type: 'POST',
        url: url,
        data: `search=${encodeURIComponent(value)}`,
        dataType: 'json',
        success: (data) => {
          if (!!data.partial) {
            // console.log(this.searchListOptionsTarget);
            this.searchListOptionsTarget.innerHTML = data.partial;
          }
          this.itemTextTarget.focus();
        },
        error: (data) => {
          this.itemTextTarget.focus();
        }
      });
    }
  }
 
}