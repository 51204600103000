import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('a').forEach(function(link) {
      if (link.host !== window.location.host) {
        link.target = "_blank";
      }
    });
    // console.log(this.element)
    this.element.innerHTML = this.element.innerHTML.replace(/\&nbsp;/g, '');
    this.element.innerHTML = this.element.innerHTML.replace(/(<br\ ?\/?>)+/, '<br>');
  }
}