import Flickity from 'flickity';


// Built this way because of this article: https://sevos.io/2017/02/27/turbolinks-lifecycle-explained.html
// see especially the tldr

$(document).on('turbo:load', function() {
  if ((!!$("#event_carousel")[0])) {
    const elem = document.querySelector('#event_carousel');
    const flkty = new Flickity(elem, {
      contain: true,
      autoPlay: 4000,
      imagesLoaded: true,
      groupCells: true
    });
    // destroy the element after navigating away so as not to break it with the back button
    $(document).on('turbo:before-render',function() {
      flkty.destroy();
    });
  }
  {once: true}

  
  if (!!$('.local_time_time_class')[0]) {
    // ###MEESH hacky way to get around the fact that local_time's Singapore acronym is wrong (SST vs SGT)
    // https://github.com/basecamp/local_time/pull/141
    setTimeout(function() {
      $('.local_time_time_class').each(function(i) {
      var text = this.innerHTML;
      var replace = text.replace("SST", "");
      this.innerHTML = replace;
      // console.log(text);
    }
      );
    }, 500);
    
  }
  
});

$(document).on('turbo:render', function() {
  if ((!!$("#event_carousel")[0])) {
    const elem = document.querySelector('#event_carousel');
    const flkty = new Flickity(elem, {
      contain: true,
      autoPlay: 4000,
      imagesLoaded: true,
      groupCells: true
    });
    // destroy the element after navigating away so as not to break it with the back button
    $(document).on('turbo:before-render',function() {
      flkty.destroy();
    });
  }
});