$(document).on('turbo:load', function() {
  
  if (!!$("#generate_hostel_reservation")[0]) {
    
    checkSubmit();
    
    $("#hostel_generated_reservation_start_date").on('input', function() {
      checkSubmit();
    });
    
    $("#hostel_generated_reservation_end_date").on('input', function() {
      checkSubmit();
    });
    
    $("#hostel_generated_reservation_email").on('input', function() {
      checkSubmit();
    });
    
    $("#hostel_generated_reservation_booking_reference").on('input', function() {
      checkSubmit();
    });
  }
  
  
  
   if (!!$("#copy_link")[0]) {
     $("#copy_link").click(async event => {
      if (!navigator.clipboard) {
        // Clipboard API not available
        event.target.textContent = 'Failed to copy.'
        return
      }
      const text = $("#link-text")[0].innerText;//"bloooooo";//event.target.innerText
      try {
        await navigator.clipboard.writeText(text)
        event.target.textContent = 'Copied to clipboard'
      } catch (err) {
        console.error('Failed to copy!', err)
      }
    })
    // $("#copy_link").click(function() {
       
    // });
    
   }
   
   
  // if (!!$("#new_reservation_request")[0]) {
  //   $("#reservation_request_submit").click(function (event) {

  //       //stop submit the form, we will post it manually.
  //       event.preventDefault();

  //       // Get form
  //       var form = $('#upload_user_image_form')[0];

  //       // Create an FormData object 
  //       var data = new FormData(form);
        
  //       // $("#profile_image")[0].innerHTML = '<div class="spinner_identifier m-5" style="text-align:center;"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div>'
  //       // If you want to add an extra field for the FormData
  //       // data.append("CustomField", "This is some extra data, testing");
  //       // console.log(data);
  //       // disabled the submit button
  //       $("#reservation_request_submit").prop("disabled", true);
  
  //       $.ajax({
  //           type: "POST",
  //           enctype: 'multipart/form-data',
  //           url: "/reservations/new_request",
  //           data: data,
  //           // processData: false,
  //           // contentType: false,
  //           dataType: "script",
  //           cache: false,
  //           timeout: 600000,
  //           success: function (data) {
                
                
  //               // $("#result").text(data);
  //               console.log("SUCCESS : ", data);

  //           },
  //           error: function (e) {
                
  //               // document.getElementById("user_image_upload_file").value=''
  //               // $(".spinner_identifier").remove();
  //               // $("#result").text(e.responseText);
  //               console.log("ERROR : ", e);
  //               // $("#btnSubmit").prop("disabled", false);

  //           }
  //       });
  //   });
  // }
   
});

let checkSubmit = function() {
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  var start_date=new Date($("#hostel_generated_reservation_start_date").val());
  var end_date=new Date($("#hostel_generated_reservation_end_date").val());
  var one_year_ago=new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  var one_year_from_now=new Date(new Date().setFullYear(new Date().getFullYear() + 1 ));
  var dates=false;
  var email=$("#hostel_generated_reservation_email").val();
  var booking_reference=$("#hostel_generated_reservation_booking_reference").val();
  if (end_date>start_date && end_date>yesterday && Math.round((end_date-start_date)/(1000 * 60 * 60 * 24)) < 63 && start_date>one_year_ago && end_date<one_year_from_now) {
    var dates=true;
    $("#hostel_generated_reservation_generate_link").prop("disabled",false);
    $("#hostel_generated_reservation_generate_link").val("Generate Booking Link")
  } else {
    var dates=false;
    $("#hostel_generated_reservation_generate_link").prop("disabled",true);
    $("#hostel_generated_reservation_generate_link").val("Generate Booking Link (dates invalid)")
    // Generate Booking Link (dates required)
  }
  
  
  if (((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))||booking_reference.length>5)&&(dates)) {
    $("#hostel_generated_reservation_generate_link").prop("disabled",true);
    $("#hostel_generated_reservation_create_reservation").prop("disabled",false);
    $("#hostel_generated_reservation_create_reservation").val("Create Reservation");
  } else {
    $("#hostel_generated_reservation_create_reservation").prop("disabled",true);
    $("#hostel_generated_reservation_create_reservation").val("Create Reservation (email or booking reference + valid dates required)");
  }

};

