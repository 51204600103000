document.addEventListener('turbo:load', function() {
  // if ($("#new_notifications")[0]) {
  
    $("#notification_icon").load(location.href+" #notification_icon>*","");
    $("#nav-bell-small-screen").load(location.href+" #nav-bell-small-screen>*","");
    
    var count=$(".navigation-bell").data("count")
    // console.log(count);
    if (!!count) {
      document.title=`(${count}) Neesh`;
    } else {
      document.title=`Neesh`;
    }
  // }
});