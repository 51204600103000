import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      lookup: 'name',
      values: this.fetchUsers,
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
    this.fieldTarget.addEventListener("tribute-replaced", this.replaced)
  }

  fetchUsers(text, callback) {
    let conversation_id = $('#conversation_messages').attr('data-conversation-id');
    let hostel_chat_id = $('#hostel_chat_messages').attr('data-hostel-chat-id');
    let event_id = $('#event_messages').attr('data-event-chat-id');
    fetch(`/mentions.json?q=${text}&hostel_chat_id=${hostel_chat_id||""}&conversation_id=${conversation_id||""}&event_id=${event_id||""}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
      // console.log(oops)
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
      // Added a caption because otherwise message validations were failing with only a single attachment and no text
      caption: "<<mention>>"
    })
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    let tributelength = endPos - startPos
    let trixStartPos = position - tributelength
    let trixEndPos = position
    this.editor.setSelectedRange([trixStartPos, trixEndPos])
    this.editor.deleteInDirection("backward")
  }
  
}